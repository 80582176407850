import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

const Dashboard = () =>
    import("@/modules/dashboard/views/Dashboard.vue");
const DashboardPowerBI = () =>
    import("@/modules/dashboard/views/DashboardPowerBI.vue");
const AssignCampDashboard = () =>
    import("@/modules/dashboard/views/AssignCampDashboard.vue");
const additionsStageDashboard = () => import("@/modules/dashboard/views/AdditionsStageDashboard.vue")
const GisDashboard = () => import("@/modules/dashboard/views/GISDashboard.vue")
export const dashboardPage = {

    path: "/",
    name: "Dashboard",
    component: DashboardLayout,
    children: [

        {
            path: "/dashboard",
            name: "Default",
            component: Dashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "",
            },
        },
        {
            path: "/dashboard-power-bi",
            name: "PowerBIDashboard",
            component: DashboardPowerBI,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "dashboard-power-bi-1",
            },
        },
        {
            path: "/camp-delivery-performance",
            name: "CampDeliveryPerformanceIndex",
            component: AssignCampDashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "dashboard-delivred-camp",
            },
        },
        {
            path: "/additions-stage",
            name: "additionsStage",
            component: additionsStageDashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "dashboard-additions-stage",
            },
        },
        {
            path: "/gis-dashboard",
            name: "Gis",
            component: GisDashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "dashboard-gis-1",
            },
        },
    ]

}