import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "ReAssignOrders",
    children: [


        {
            path: "/about-signature",
            name: "AboutSignatureReassign",
            component: () => import("@/modules/reassign/views/re_delivery/NeedSignature.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-signature",
            },
        },
        {
            path: "/re-assign-orders/create",
            name: "ReAssignOrderPageCreate",
            component: () => import("@/modules/reassign/views/New.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-create",
            },
        },
        {
            path: "/re-assign-orders/:id",
            name: "ReAssignOrderPageEdit",
            component: () => import("@/modules/reassign/views/Edit.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-form-view",
            },
        },

        {
            path: "/re-assign-orders",
            name: "ReAssignOrderPage",
            component: () => import("@/modules/reassign/views/Show.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-index",
            },
        },

    ]
};