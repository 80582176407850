
class reAssignOrderService {
  get_orders(page, post) {
    return axios.post('general/reassign_order_gov?page=' + page, post)
  }

  add_order(data) {
    return axios.post('general/reassign_order_gov/store', data)
  }

  store_krokey(data) {
    return axios.post('general/reassign_kroky_documents/store', data)
  }

  update_krokey(data) {
    return axios.post('general/reassign_kroky_documents/update', data)
  }

  delete_krokey(id) {
    return axios.post('general/reassign_kroky_documents/delete/' + id)
  }

  view_kroky(id) {
    return axios.post('general/reassign_kroky_documents/index/' + id)
  }

  sign_order(data) {
    return axios.post('general/reassign_form/sign-form', data)
  }



  proccess_order(data, type) {
    return axios.post('general/reassign_order_flow/' + type, data)
  }

  approve_order(data) {
    return axios.post('general/reassign_order_flow/approve', data)
  }

  reject_order(data) {
    return axios.post('general/reassign_order_flow/reject', data)
  }



  get_form_signer(data) {
    return axios.post('general/reassign_form/get-form-signer', data)
  }

  update_form_signer(data) {
    return axios.post('general/reassign_form/update-form-signer', data)
  }



  answer_questions(data) {
    return axios.post('general/reassign_order_form/store', data)
  }
  remove_answer(id) {
    return axios.delete('general/reassign_order_form/delete/' + id)
  }

  upload_disclaimer_attachment(data) {
    return axios.post('general/reassign_disclaimer_attachments/store', data)

  }
  delete_disclaimer_attachment(id) {
    return axios.post('general/reassign_disclaimer_attachments/delete/' + id)

  }

}
export default new reAssignOrderService();