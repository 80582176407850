export const dashboard = {
    action: "fa fa-home",
    title: "Home",
    permission: "dashboard",
    items: [
        {
            title: "Home",
            prefix: "I",
            link: "/dashboard",
            permission: "",
        },
        {
            title: "Data panels",
            prefix: "I",
            link: "/dashboard-power-bi",
            permission: "dashboard-power-bi-1",
        },
        // {
        //     title: "CampDeliveryPerformanceIndex",
        //     prefix: "I",
        //     link: "/camp-delivery-performance",
        //     permission: "dashboard-delivred-camp",
        // },
        // {
        //     title: "additionsStage",
        //     prefix: "I",
        //     link: "/additions-stage",
        //     permission: "dashboard-additions-stage",
        // },
        // {
        //     title: "gisDashboard",
        //     prefix: "I",
        //     link: "/gis-dashboard",
        //     permission: "dashboard-gis-1",
        // },
    ],
};